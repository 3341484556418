import React, {Component, Fragment} from 'react';
import {Trans, withTranslation} from 'react-i18next';
import SContainer from '../SContainer';
import {PAYMETHOD_GCC, TYPE_PRODUCT_FREE_TRIAL_SUBSCRIPTION, TYPE_PRODUCT_SUBSCRIPTION} from '../../utils/globalConstants';
import {URL_ORDERS_CANCEL_API, URL_ORDERS_GET_INVOICES_API, URL_ORDERS_ORDER_DETAILS_PAGE, URL_PAYMETHODS_SUBSCRIPTION_UPDATE_PAGE, URL_SUPPORT_HELP_EMAIL_PAGE} from '../../utils/urlConstant';
import {ERR_CODE_SUCCESS, MSG_SHOULD_NEVER_HAPPEN} from '../../utils/errorConstant';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {createReturnUrlParam, getParams} from '../../utils/queryUtil';
import {paymethodToPayType, PAYTYPE_STATUS_CC, PAYTYPE_STATUS_CP, PAYTYPE_STATUS_FT, PAYTYPE_STATUS_PP, PAYTYPE_STATUS_SP, VIEW_TYPE_ACTIVE_SUBSCRIPTION, VIEW_TYPE_CANCELED_SUBSCRIPTION, VIEW_TYPE_ORDERS} from "./OrdersHistoryPage";
import {formatDpiDate} from "../../utils/simpleDateUtils";
import Footer from "../Footer";
import InfoMenu from "../dimdropdown/InfoMenu";
import {FROM_ORDER_DETAILS, FROM_SUBSCRIPTION_DETAILS, TO_CP, TO_PM} from "./HelpEmailPage";
import {errorCode2Msg, payType2Msg} from "../../translations/i18n-helper";
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

export const getInvoiceDetailPage = (viewType, invoiceId) => {
  return URL_ORDERS_ORDER_DETAILS_PAGE + "?type=" + viewType + "&invoiceId=" + invoiceId;
};

@autobind
class OrderDetailsPage extends Component {
  constructor(props) {
    super(props);

    const queryString = getParams(window.location.search);

    this.state = {
      invoiceId: queryString.invoiceId,
      viewType: queryString.type,
      invoiceDetail: null,
      subscriptionDetail: null
    };
  }

  handleClick(sendType, templateType) {
    window.location.assign(URL_SUPPORT_HELP_EMAIL_PAGE
        + "?sendType=" + sendType
        + "&templateType=" + templateType
        + "&viewType=" + this.state.viewType
        + "&invoiceId=" + this.state.invoiceId);
  }

  cancelOrder(invoiceId) {
    this.props.showProgressBar();

    httpClient
        .post(URL_ORDERS_CANCEL_API, {
          invoiceId: invoiceId
        })
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          this.fetchData();
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  renderContactUs(invoiceDetail, isViewOrder) {
    let fromWhichPage = isViewOrder ? FROM_ORDER_DETAILS : FROM_SUBSCRIPTION_DETAILS;

    return (
        <div className={`s-contactus`}>
          {invoiceDetail.appSupportEmail &&
          <p>
            {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_63')}<br/>
            <Trans i18nKey="TV_SID_PYMENT_WEBPORTAL_42704_STRING_64">
              Service Provider Email: <a onClick={() => this.handleClick(TO_CP, fromWhichPage)}>{{A: invoiceDetail.appSupportEmail}}</a>
            </Trans>
          </p>
          }
          <p>
            <Trans i18nKey={'TV_SID_PYMENT_WEBPORTAL_42704_STRING_65'}>
              Contact <a onClick={() => this.handleClick(TO_PM, fromWhichPage)}>Samsung Checkout</a>
            </Trans>
          </p>
        </div>
    );
  }

  renderCancelButton(invoiceDetail) {
    let buttonText = this.props.t('TV_SID_PAYMENT_UNSUBSCRIBE_KR_WEBPORTAL');
    let confirmText = this.props.t('COM_SID_DO_YOU_WANT_TO_CANCEL_THE_SUBSCRIPTION');

    return (
        <button type={`button`}
                className={`s-btn s-btn-default`}
                onClick={() => this.props.showPopupConfirm(
                    confirmText,
                    () => this.cancelOrder(invoiceDetail.invoiceId))}>
          {buttonText}
        </button>
    );
  }

  renderPaymentAmount(invoiceDetail) {
    const {v_productPrice, v_couponAmt, v_taxAmount, v_paymentPrice, v_exchangedUsePointAmount, currSmbl} = invoiceDetail;

    let isCoupon = invoiceDetail.couponAmt > 0;
    const isSubscriptionType = invoiceDetail.productType === TYPE_PRODUCT_SUBSCRIPTION || invoiceDetail.productType === TYPE_PRODUCT_FREE_TRIAL_SUBSCRIPTION;
    let isFreeTrial = isSubscriptionType && parseInt(invoiceDetail.freeTrialDayCount, 10) > 0;

    return (
        <div className={`nodi_h s-topline-section`}>
          <h4 className={`tit_payment`}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_59')}</h4>
          <table className={`s-noline-table3`}>
            <caption>Payment Amount info</caption>
            <tbody>
            <tr>
              <th scope={`row`}>{this.props.t('TV_SID_PAYMENT_ORIGINAL_PRICE')}</th>
              <td>{currSmbl}{v_productPrice}</td>
            </tr>
            {isCoupon &&
            <tr>
              <th scope={`row`}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_77')}</th>
              <td>- {currSmbl}{v_couponAmt}</td>
            </tr>
            }
            {(parseFloat(invoiceDetail.taxAmount) !== 0) &&
            <tr>
              <th scope={`row`} className={`t3_b`}>{this.props.t('COM_TAX')}</th>
              <td>+ {currSmbl}{v_taxAmount}</td>
            </tr>
            }
            {(parseFloat(invoiceDetail.v_exchangedUsePointAmount) !== 0) &&
                <tr>
                  <th scope={`row`} className={`t3_b`}>{this.props.t('TV_SID_BILLING_CDDEC_SAMSUNG_REWARDS')}</th>
                  <td>- {currSmbl}{v_exchangedUsePointAmount}</td>
                </tr>
            }
            </tbody>
            <tfoot>
            <tr>
              <th scope={`row`}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_59')}</th>
              <td>
                {currSmbl}{v_paymentPrice}{isFreeTrial && <Fragment><br/>({this.props.t('TV_SID_BILLING_FREE_TRIAL')})</Fragment>}
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
    );
  }

  renderRefundStatus(details, isViewOrder) {
    return (
        <Fragment>
          <tr>
            <th scope={'row'}>{this.props.t('COM_SID_STATUS')}</th>
            {isViewOrder ? (
                <td>
                  {details.cancelStatus === "true" ? (
                      this.props.t('TV_SID_BILLING_MIX_REFUNDED', {date: formatDpiDate(details.cancelTime, "/", "MMDDYY", true)})
                  ) : (
                      this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_61')
                  )}
                </td>
            ) : (
                <td>
                  {details.joinEndStatus !== "00" ? (
                      this.props.t('TV_SID_PAYMENT_MIX_UNSUBSCRIBED_KR_WEBPORTAL', {date: formatDpiDate(details.cancelTime, "/", "MMDDYY", true)})
                  ) : (
                      <Fragment>
                        {this.props.t('TV_SID_PAYMENT_SUBSCRIBED')}
                        {details.lastPaymentTime &&
                        <span className={`s-info-tiny`}>
                          {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_75', {date: formatDpiDate(details.lastPaymentTime, "/", "MMDDYY", true)})}
                        </span>
                        }
                        {details.nextPaymentTime &&
                        <span className={`s-info-tiny`}>
                          {this.props.t('TV_SID_BILLING_MIX_NEW_PAYMENT', {date: formatDpiDate(details.nextPaymentTime, "/", "MMDDYY", true)})}
                        </span>
                        }
                      </Fragment>
                  )}
                </td>
            )}
          </tr>
          {isViewOrder ? (
              <Fragment>
                {details.cancelStatus === "true" && parseFloat(details.v_exchangedUsePointAmount) === 0 &&
                    <Fragment>
                      <tr>
                        <th scope={'row'}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_59')}</th>
                        <td>{details.currSmbl}{details.v_paymentPrice}</td>
                      </tr>
                      <tr>
                        <th scope={'row'}>{this.props.t('TV_SID_BILLING_REFUND_AMOUNT')}</th>
                        <td>{details.currSmbl}{details.v_refundAmt}</td>
                      </tr>
                    </Fragment>
                }
              </Fragment>
          ) : (
              <Fragment>
                {details.joinEndStatus !== "00" &&
                <tr>
                  <th scope={'row'}>{this.props.t('COM_EXPIRATION_DATE_KR_DATE')}</th>
                  <td>{formatDpiDate(details.joinEndTime, "/", "MMDDYY", true)}</td>
                </tr>
                }
              </Fragment>
          )}
        </Fragment>
    );
  }

  renderRealPaymentMethodForViewOrder(payType, displayPayMethod) {
    return (
        <Fragment key={payType}>
          {displayPayMethod}
          <InfoMenu>
            {' ' + this.props.t('TV_SID_ACTUAL_CREDIT_PAYPAL_REFUND')}
          </InfoMenu>
        </Fragment>
    );
  }

  renderCouponPaymentMethodForViewOrder(payType, displayPayMethod) {
    return (
        <Fragment key={payType}>
          {displayPayMethod}
          <InfoMenu>
            {' ' + this.props.t('TV_SID_COUPON_NOT_REFUND')}
          </InfoMenu>
        </Fragment>
    );
  }

  renderPaymentMethodForViewOrder(invoiceDetail) {
    // 무료체험인 경우
    if (parseInt(invoiceDetail.freeTrialDayCount, 10) > 0) {
      return this.renderRealPaymentMethodForViewOrder(
          PAYTYPE_STATUS_FT,
          parseInt(invoiceDetail.freeTrialDayCount) + " " + this.props.t("COM_TV_SID_HOTEL_DAYS") + this.props.t("TV_SID_BILLING_FREE_TRIAL"));
    }

    // 무료체험이 아닌 경우
    let realPaymentMethod = this.renderRealPaymentMethod(invoiceDetail);
    let subPaymentMethod;
    if (parseFloat(invoiceDetail.couponAmt) > 0) { // 쿠폰
      let displayMethod;

      let isSubscriptionProduct = invoiceDetail.productType === TYPE_PRODUCT_SUBSCRIPTION || invoiceDetail.productType === TYPE_PRODUCT_FREE_TRIAL_SUBSCRIPTION;
      if (isSubscriptionProduct && parseInt(invoiceDetail.totalCouponCycle, 10) > 0) { // 무료체험쿠폰
        displayMethod = payType2Msg(this.props.t, PAYTYPE_STATUS_CP) + " (" + invoiceDetail.couponName + "[" + invoiceDetail.usedCouponCycle + " / " + invoiceDetail.totalCouponCycle + "])";
      } else { // free or fixed or percentage 쿠폰
        displayMethod = payType2Msg(this.props.t, PAYTYPE_STATUS_CP) + " (" + invoiceDetail.couponName + ")";
      }

      subPaymentMethod = this.renderCouponPaymentMethodForViewOrder(PAYTYPE_STATUS_CP, displayMethod);
    }

    return (
        <Fragment>
          {realPaymentMethod}
          {(realPaymentMethod && subPaymentMethod) &&
          <br/>
          }
          {subPaymentMethod}
        </Fragment>
    );
  }

  renderRealPaymentMethod(invoiceDetail) {
    switch (paymethodToPayType(invoiceDetail.payMethod)) {
      case PAYTYPE_STATUS_PP:
        return payType2Msg(this.props.t, PAYTYPE_STATUS_PP) + " (" + invoiceDetail.paypalInfo.paypalId + ")";
      case PAYTYPE_STATUS_CC: {
        let cardName = invoiceDetail.creditCardInfo.cardName || payType2Msg(paymethodToPayType(invoiceDetail.payMethod));
        let cardAccountLast4Number = invoiceDetail.creditCardInfo.cardAccountLast4Number || "xxxx"
        return cardName + " (X-" + cardAccountLast4Number + ")";
      }
      case PAYTYPE_STATUS_SP: {
        let displayPayMethod = payType2Msg(this.props.t, PAYTYPE_STATUS_SP);
        if (invoiceDetail.samsungPayInfo.deviceNickName) {
          displayPayMethod += " (" + invoiceDetail.samsungPayInfo.deviceNickName + ")"
        }

        return displayPayMethod;
      }
      default:
        return payType2Msg(this.props.t, paymethodToPayType(invoiceDetail.payMethod)); // Will not be used in production
    }
  }

  renderDisplayPaymethodWithAngleBracket(displayPaymethod, subscriptionId) {
    return (
        <Fragment>
          <a className="s-font-color"
             onClick={() => {
               const url = URL_PAYMETHODS_SUBSCRIPTION_UPDATE_PAGE.replace(":id", subscriptionId)
                   + "?" + createReturnUrlParam(window.location.pathname + window.location.search);
               window.location.assign(url);
             }}>
            <div className="s-width-65 s-display-inline">{displayPaymethod}</div>
            <div className="s-display-inline s-font-weight-900">&#x3009;</div>
          </a>
        </Fragment>
    );
  }

  renderTopLineSection(detail, isViewOrder) {
    let captionContent = isViewOrder ? "history info" : "subscription info";
    let detailsIdLabel = isViewOrder
        ? this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_57')
        : this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_73');
    let detailsDateLabel = isViewOrder
        ? this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_58')
        : this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_74');

    const canChangeSubsPayment = !Object.is(detail.cancelStatus, "true") && Object.is(detail.payMethod, PAYMETHOD_GCC);

    let displayPaymethod;

    if (isViewOrder) {
      displayPaymethod = this.renderPaymentMethodForViewOrder(detail);
    } else if (!isViewOrder && canChangeSubsPayment) {
      displayPaymethod = this.renderDisplayPaymethodWithAngleBracket(this.renderRealPaymentMethod(detail), detail.invoiceId);
    } else if (!isViewOrder && !canChangeSubsPayment) {
      displayPaymethod = this.renderRealPaymentMethod(detail);
    }

    // Note, detail.invoiceId will show subscription id when isViewOrder === false
    return (
        <div className={`s-topline-section`}>
          <table className={'s-noline-table2'}>
            <caption>{captionContent}</caption>
            <tbody>
            <tr>
              <th scope={'row'}>{detailsIdLabel}</th>
              <td id={'invoiceId_param'}>{detail.invoiceId}</td>
            </tr>
            <tr>
              <th scope={'row'}>{detailsDateLabel}</th>
              <td>{formatDpiDate(detail.orderTime, "/", "MMDDYY", true)}</td>
            </tr>
            {isViewOrder && this.renderRefundStatus(detail, isViewOrder)}
            <tr>
              <th scope={'row'}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_8')}</th>
              <td>{displayPaymethod}</td>
            </tr>
            {!isViewOrder && this.renderRefundStatus(detail, isViewOrder)}
            </tbody>
          </table>
        </div>
    );
  }

  renderAppInfo(detail) {
    return (
        <div className={`s-app-view`}>
          <img src={detail.appIconUrl} alt={detail.appName}/>
          <h3 className={`s-app-title`}>{detail.appName}</h3>
          <strong className={`s-app-subtitle`}>{detail.productTitle}</strong>
        </div>
    );
  }

  renderSubscriptionInfo(subscriptionDetail) {
    if (!subscriptionDetail) {
      return;
    }

    return (
        <div className={`s-phdetail-section`}>
          {this.renderAppInfo(subscriptionDetail)}
          {this.renderTopLineSection(subscriptionDetail, false)}
          {subscriptionDetail.joinEndStatus === "00" &&
          this.renderCancelButton(subscriptionDetail, false)
          }
          {this.renderContactUs(subscriptionDetail, false)}
        </div>
    );
  }

  renderOrderDetail(invoiceDetail) {
    if (!invoiceDetail) {
      return;
    }

    return (
        <div className={`s-phdetail-section`}>
          {this.renderAppInfo(invoiceDetail)}
          {this.renderTopLineSection(invoiceDetail, true)}
          {(invoiceDetail.cancelStatus !== "true" || (parseFloat(invoiceDetail.v_exchangedUsePointAmount)) !== 0) &&
          this.renderPaymentAmount(invoiceDetail)
          }
          {this.renderContactUs(invoiceDetail, true)}
        </div>
    );
  }

  fetchData() {
    this.props.showProgressBar();

    httpClient
        .post(URL_ORDERS_GET_INVOICES_API, {
          invoiceId: this.state.invoiceId,
          viewType: this.state.viewType
        })
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          if (response.data.invoiceDetails.length > 0) {
            this.setState({invoiceDetail: response.data.invoiceDetails[0]});
          } else if (response.data.subscriptionInfo.length > 0) {
            this.setState({subscriptionDetail: response.data.subscriptionInfo[0]});
          } else {
            throw new Error(MSG_SHOULD_NEVER_HAPPEN);
          }
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  render() {
    const {invoiceDetail, subscriptionDetail, viewType} = this.state;

    return (
        <PageContainer
            hidden={true}
            showNav={true}
            showSubHeader={true}
            url={this.props.url}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            subHeaderText={this.props.t('COM_SID_PURCHASE_HISTORY_KR_MOBILE')}>
          <SContainer>
            <div className={`container-fluid`}>
              <div className={`row`}>
                <div className={`col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                  {viewType === VIEW_TYPE_ORDERS &&
                  this.renderOrderDetail(invoiceDetail)
                  }
                  {(viewType === VIEW_TYPE_ACTIVE_SUBSCRIPTION || viewType === VIEW_TYPE_CANCELED_SUBSCRIPTION) &&
                  this.renderSubscriptionInfo(subscriptionDetail)
                  }
                </div>
              </div>
            </div>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }

  componentDidMount() {
    this.fetchData();
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(OrderDetailsPage)));

